import * as React from 'react';
import './index.css';
import {useEffect} from "react";




const Features: React.FunctionComponent = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (<>
        <div className="container px-4 py-5" id="icon-grid" style={{marginTop:75}}>
            <h2 className="pb-2 border-bottom">Features</h2>

            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5">
                <div className="col d-flex align-items-start">
                    <svg className="bi text-muted flex-shrink-0 me-3" width="1.75em" height="1.75em">
                        <use xlinkHref="#bootstrap"></use>
                    </svg>
                    <div>
                        <h4 className="fw-bold mb-0">Fast Payments</h4>
                        <p>Same-day collection available.</p>
                    </div>
                </div>
                <div className="col d-flex align-items-start">
                    <svg className="bi text-muted flex-shrink-0 me-3" width="1.75em" height="1.75em">
                        <use xlinkHref="#cpu-fill"></use>
                    </svg>
                    <div>
                        <h4 className="fw-bold mb-0">Powerful Reporting</h4>
                        <p>Powerful reporting your business needs to get visibility into your properties.</p>
                    </div>
                </div>
                <div className="col d-flex align-items-start">
                    <svg className="bi text-muted flex-shrink-0 me-3" width="1.75em" height="1.75em">
                        <use xlinkHref="#calendar3"></use>
                    </svg>
                    <div>
                        <h4 className="fw-bold mb-0">Maintenance Ticketing</h4>
                        <p>Manage the full lifecycle of maintenance requests from tenants.</p>
                    </div>
                </div>
                <div className="col d-flex align-items-start">
                    <svg className="bi text-muted flex-shrink-0 me-3" width="1.75em" height="1.75em">
                        <use xlinkHref="#home"></use>
                    </svg>
                    <div>
                        <h4 className="fw-bold mb-0">Mobile First Experience</h4>
                        <p>A mobile first experience for tenants.</p>
                    </div>
                </div>
                <div className="col d-flex align-items-start">
                    <svg className="bi text-muted flex-shrink-0 me-3" width="1.75em" height="1.75em">
                        <use xlinkHref="#speedometer2"></use>
                    </svg>
                    <div>
                        <h4 className="fw-bold mb-0">Cloud Solution</h4>
                        <p>Always available to scale with your business.</p>
                    </div>
                </div>
                <div className="col d-flex align-items-start">
                    <svg className="bi text-muted flex-shrink-0 me-3" width="1.75em" height="1.75em">
                        <use xlinkHref="#toggles2"></use>
                    </svg>
                    <div>
                        <h4 className="fw-bold mb-0">Open APIs</h4>
                        <p>Integrate with third-party apps.</p>
                    </div>
                </div>
                <div className="col d-flex align-items-start">
                    <svg className="bi text-muted flex-shrink-0 me-3" width="1.75em" height="1.75em">
                        <use xlinkHref="#geo-fill"></use>
                    </svg>
                    <div>
                        <h4 className="fw-bold mb-0">Inbound Leads</h4>
                        <p>Capture inbound leads via email, phone, and sms in centralized portal.</p>
                    </div>
                </div>
                <div className="col d-flex align-items-start">
                    <svg className="bi text-muted flex-shrink-0 me-3" width="1.75em" height="1.75em">
                        <use xlinkHref="#tools"></use>
                    </svg>
                    <div>
                        <h4 className="fw-bold mb-0">Workflows</h4>
                        <p>Create data-driven workflows.</p>
                    </div>
                </div>
            </div>
        </div>

        <div className="container px-4 py-5" id="custom-cards">
            <h2 className="pb-2 border-bottom">Better Experiences</h2>

            <div className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5">
                <div className="col">
                    <div className="card-features-experience-1 card card-cover card-cover-feature-1 h-100 overflow-hidden bg-dark rounded-5 shadow-lg"
                         >
                        <div className="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
                            <h2 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">Easy to use mobile experience for tenants</h2>
                            <ul className="d-flex list-unstyled mt-auto">
                                <li className="me-auto">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                                         className="bi bi-phone" viewBox="0 0 16 16">
                                        <path
                                            d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/>
                                        <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                    </svg>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col">
                    <div className="card-features-experience-2 card card-cover card-cover-feature-1 h-100 overflow-hidden bg-dark rounded-5 shadow-lg"
                         >
                        <div className="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
                            <h2 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">Powerful experience for property managers </h2>
                            <ul className="d-flex list-unstyled mt-auto">
                                <li className="me-auto">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                                         className="bi bi-building" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                              d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
                                        <path
                                            d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
                                    </svg>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col">
                    <div className="card-features-experience-3 card card-cover card-cover-feature-1 h-100 overflow-hidden bg-dark rounded-5 shadow-lg"
                        >
                        <div className="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
                            <h2 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">Investor portal for visibility into property performance</h2>
                            <ul className="d-flex list-unstyled mt-auto">
                                <li className="me-auto">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                                         className="bi bi-graph-up-arrow" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                              d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z"/>
                                    </svg>
                                </li>


                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
};

export default Features;