import React from 'react';
import './App.css';
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import HomeContainer from "./Components/HomeContainer";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/*" element={<HomeContainer/>} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
