import * as React from 'react';
import './index.css';
import {useEffect} from "react";


const Investors: React.FunctionComponent = () => {


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (<>
        <div id="investor-carousel" className="carousel slide" data-bs-ride="carousel">

            <div className="carousel-inner">

                <div className="carousel-item active">
                    <div className="container">
                        <div className="carousel-caption">
                            <h1>PropertyMQ is innovating the real estate software industry</h1>
                            <p></p>
                            <p></p>
                        </div>
                    </div>
                </div>

            </div>

        </div>
        <div className="container">
            <div className={"row"}>
                <div className={"col-md-12"} style={{paddingTop: 100, paddingBottom: 400}}>
                    <h3>Interested in investing in us?</h3>
                    <p>
                        <a className="link-secondary text-decoration-none" href="mailto:ir@propertymq.com">ir@propertymq.com
                        </a>
                    </p>
                    <p>
                        <a className="link-secondary text-decoration-none"
                           href="tel:18666701397">866.670.1397</a>
                    </p>
                </div>
            </div>
        </div>
    </>);
};

export default Investors;