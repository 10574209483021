import * as React from 'react';
import {useEffect} from "react";
import {Link} from "react-router-dom";


const Pricing: React.FunctionComponent = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (<>
        <div className="container px-4 py-5 hero-1">
            <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
                <h1 className="display-4 fw-normal">Pricing</h1>
                <p className="fs-5 text-muted">Simple pricing to meet your business needs.</p>
            </div>
            <main>
                <div className="row row-cols-1 row-cols-md-3 mb-3 text-center justify-content-md-center">
                    <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm">
                            <div className="card-header py-3">
                                <h4 className="my-0 fw-normal">Basic</h4>
                            </div>
                            <div className="card-body">
                                <h1 className="card-title pricing-card-title">$1<small
                                    className="text-muted fw-light">/mo/unit</small></h1>
                                <ul className="list-unstyled mt-3 mb-4">
                                    <li>Online Payments Service</li>
                                    <li>Resident and Manager Online Portal</li>
                                    <li>Online Maintenance Ticketing</li>
                                    <li>Mobile Experience</li>
                                    <li>Online Application and Marketing</li>
                                    <li>Cloud Based Solution</li>
                                </ul>
                                <Link to={"/book-a-demo"}>
                                    <button type="button" className="w-100 btn btn-lg btn-primary">Book a Demo</button>
                                </Link>
                                <small>*Minimum monthly fee of $99/mo. Onboarding fee based on portfolio size.</small>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm border-primary">
                            <div className="card-header py-3 text-white bg-primary border-primary">
                                <h4 className="my-0 fw-normal">Plus</h4>
                            </div>
                            <div className="card-body">
                                <h1 className="card-title pricing-card-title">$2<small
                                    className="text-muted fw-light">/mo/unit</small></h1>
                                <ul className="list-unstyled mt-3 mb-4">
                                    <li>Everything in the Basic Plan</li>
                                    <li>Same-day Collection</li>
                                    <li>Advanced Support</li>
                                    <li>Data Access via Open APIs</li>
                                    <li>Workflows</li>
                                    <li>Accounting Reports</li>
                                </ul>
                                <Link to={"/book-a-demo"}>
                                    <button type="button" className="w-100 btn btn-lg btn-primary">Book a Demo</button>
                                </Link>
                                <small>*Minimum monthly fee of $500/mo. Onboarding fee based on portfolio size.</small>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
        </div>
    </>);
};

export default Pricing;