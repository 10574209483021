
export enum BootstrapValidateClass {
    isvalid = "is-valid", isinvalid = "is-invalid", unvalidated = ""
}

export type FormRegex = {
    expression: RegExp,
    helpText: string
}

export type FormElementValidation = {
    id: string,
    validate: ValidateElementFunction,
    regex: FormRegex,
    required: boolean
}

export type ValidateElementFunction = (value: string, required: boolean, validation: RegExp) => boolean;

export const formInputValidate: ValidateElementFunction = (value: string, required: boolean, validation: RegExp): boolean => {
    if (required) {
        if (value && value.match(validation)) {
            return true;
        }
        return false;
    } else {
        if ((value && value.length === 0) || !value) {
            return true;
        }
        if (value && value.match(validation)) {
            return true;
        }
        return false;
    }
}

export const PhoneNumberRegex:FormRegex = {
    expression:/^\b\d{3}[-.]?\d{3}[-.]?\d{4}\b$/,
    helpText: "Must contain 10 digits in the format xxx-xxx-xxxx."
}

export const GenericInputRegex:FormRegex = {
    expression: /.+/,
    helpText: "Must contain at least one character."
}

export const EmailRegex:FormRegex = {
    expression:/^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/,
    helpText: "Must be a valid email address."
}