import * as React from 'react';
import {useEffect} from "react";
import './index.css'
import {Link} from "react-router-dom";


const CreateAccount: React.FunctionComponent = () => {

    let MANAGER_ENDPOINT = "https://manage.propertymq.com";
    let TENANT_ENDPOINT = "https://tenant.propertymq.com";
    let INVESTOR_ENDPOINT = "https://manage.propertymq.com";
    let VENDOR_ENDPOINT = "https://manage.propertymq.com";

    if(window.location.hostname === "localhost" && parseInt(window.location.port) >= 3000 && parseInt(window.location.port) < 4000) {
        MANAGER_ENDPOINT = "http://localhost:3000";
        TENANT_ENDPOINT = "http://localhost:3000";
        INVESTOR_ENDPOINT = "http://localhost:3000";
        VENDOR_ENDPOINT = "http://localhost:3000";
    }
    if(window.location.hostname === "localhost" && parseInt(window.location.port) >= 4000 && parseInt(window.location.port) < 5000) {
        MANAGER_ENDPOINT = "http://localhost:4004";
        TENANT_ENDPOINT = "http://localhost:4000";
        INVESTOR_ENDPOINT = "http://localhost:4004";
        VENDOR_ENDPOINT = "http://localhost:4004";
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (<>
        <div className="container px-4 " id="hanging-icons" style={{paddingTop: '100px', paddingBottom: '100px'}}>
            <h2 className="pb-2 border-bottom">Sign-up for a New Account</h2>
            <p>Select the type of account you would like to enroll</p>
            <div className="row g-4 py-5 row-cols-1 row-cols-lg-4 ">
                <a href={TENANT_ENDPOINT + "/signup_callback"} className={"signup-link"}>
                    <div className="col d-flex">
                        <div className={"signup-option center"}>
                            <h2>Tenant</h2>
                            <p>Submit applications and manage your lease.</p>

                        </div>
                    </div>
                </a>
                <a href={MANAGER_ENDPOINT + "/signup_callback"} className={"signup-link"}>
                    <div className="col d-flex">
                        <div className={"signup-option center"}>
                            <h2>Manager</h2>
                            <p>Manage properties as an owner, manager, maintenance, accounting, and leasing agent.</p>

                        </div>
                    </div>
                </a>
                <a href={INVESTOR_ENDPOINT + "/signup_callback"} className={"signup-link"}>
                    <div className="col d-flex">
                        <div className={"signup-option center"}>
                            <h2>Investor</h2>
                            <p>Manage real estate investments.</p>

                        </div>
                    </div>
                </a>
                <a href={VENDOR_ENDPOINT + "/signup_callback"} className={"signup-link"}>
                    <div className="col d-flex">

                        <div className={"signup-option center"}>
                            <h2>Vendor</h2>
                            <p>Manage invoicing and payments.</p>

                        </div>
                    </div>
                </a>
            </div>
        </div>
    </>);
};

export default CreateAccount;