import * as React from 'react';
import './index.css';
import {useEffect} from "react";




const OpenAPI: React.FunctionComponent = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (<>
        <div className="container px-4 py-5 open-api" id="hanging-icons">
            <h2 className="pb-2 border-bottom">Open APIs at no extra charge</h2>
            <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
                <div className="col d-flex align-items-start">
                    <div className="icon-square bg-light text-dark flex-shrink-0 me-3">
                        <svg className="bi" width="1em" height="1em">
                            <use xlinkHref="#toggles2"></use>
                        </svg>
                    </div>
                    <div>
                        <h2>Payments and Invoicing</h2>
                        <p>Invoice and collect payments from tenants.</p>

                    </div>
                </div>
                <div className="col d-flex align-items-start">
                    <div className="icon-square bg-light text-dark flex-shrink-0 me-3">
                        <svg className="bi" width="1em" height="1em">
                            <use xlinkHref="#cpu-fill"></use>
                        </svg>
                    </div>
                    <div>
                        <h2>Property Information</h2>
                        <p>Update property information, availability, and pricing in real time.</p>

                    </div>
                </div>
                <div className="col d-flex align-items-start">
                    <div className="icon-square bg-light text-dark flex-shrink-0 me-3">
                        <svg className="bi" width="1em" height="1em">
                            <use xlinkHref="#tools"></use>
                        </svg>
                    </div>
                    <div>
                        <h2>Tenant Procurement</h2>
                        <p>Access inbound leads and engage customers over sms, phone, and email.</p>

                    </div>
                </div>
                <div className="col d-flex align-items-start">
                    <div className="icon-square bg-light text-dark flex-shrink-0 me-3">
                        <svg className="bi" width="1em" height="1em">
                            <use xlinkHref="#toggles2"></use>
                        </svg>
                    </div>
                    <div>
                        <h2>Reports</h2>
                        <p>Integrate with our powerful reporting tools.</p>

                    </div>
                </div>
                <div className="col d-flex align-items-start">
                    <div className="icon-square bg-light text-dark flex-shrink-0 me-3">
                        <svg className="bi" width="1em" height="1em">
                            <use xlinkHref="#cpu-fill"></use>
                        </svg>
                    </div>
                    <div>
                        <h2>Maintenance</h2>
                        <p>Engage and fulfill maintenance requests.</p>

                    </div>
                </div>
                <div className="col d-flex align-items-start">
                    <div className="icon-square bg-light text-dark flex-shrink-0 me-3">
                        <svg className="bi" width="1em" height="1em">
                            <use xlinkHref="#tools"></use>
                        </svg>
                    </div>
                    <div>
                        <h2>Leases</h2>
                        <p>Procure and manage leases.</p>

                    </div>
                </div>
            </div>
        </div>
    </>);
};

export default OpenAPI;