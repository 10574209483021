import * as React from 'react';
import {useState} from "react";
import {Link} from "react-router-dom";

const ServicesDropDown: React.FunctionComponent = () => {
    const [showSelected, setShowSelected] = useState(false);
    return (<>
        <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button"
           aria-expanded="false"
           onMouseEnter={() => {
               setShowSelected(true);
           }}
           onMouseLeave={() => {
               setShowSelected(false);
           }}>Software Platforms</a>
        <ul className="dropdown-menu">
            <li><Link className="dropdown-item" to={"/"}>PropertyMQ</Link></li>
            <li><Link className="dropdown-item" to={"/open-api"}>PropertyMQ Open API</Link></li>
        </ul>

        <div className={(showSelected) ? "navigation-selected" : "navigation-not-selected"}/>
    </>);
};

export default ServicesDropDown;