import * as React from 'react';
import Navigation from "../Navigation";
import {Route, Router, Routes} from "react-router-dom";
import PropertyManagementPlatform from "../PropertyManagementPlatform";
import Footer from "../Footer";
import Pricing from "../Pricing";
import Features from "../Features";
import Team from "../Team";
import Investors from "../Investors";
import Location from "../Location";
import ContactUs from "../ContactUs";
import BookDemo from "../BookDemo";
import OpenAPI from "../OpenAPI";
import CreateAccount from "../CreateAccount";


const HomeContainer: React.FunctionComponent = () => {
    return (<>
        <Navigation/>
        <main id={"main"}>
            <Routes>
                <Route index element={<PropertyManagementPlatform/>} />
                <Route path={"/pricing"} element={<Pricing/>} />
                <Route path={"/features"} element={<Features/>} />
                <Route path={"/team"} element={<Team/>} />
                <Route path={"/investors"} element={<Investors/>} />
                <Route path={"/location"} element={<Location/>} />
                <Route path={"/contact-us"} element={<ContactUs/>} />
                <Route path={"/book-a-demo"} element={<BookDemo/>} />
                <Route path={"/open-api"} element={<OpenAPI/>} />
                <Route path={"/create-account"} element={<CreateAccount/>} />
            </Routes>
        </main>
        <Footer/>
    </>);
};

export default HomeContainer;