
type BookADemoBody = {
    email: string,
    company: string,
    firstName: string,
    lastName: string,
    phone: string,
    countryCode: string
}

type bookADemo = (body: BookADemoBody) => Promise<Response>;

export const bookADemo: bookADemo = (async (body) => {
    return fetch("/api/demo", {
        mode: 'cors',
        method: "POST",
        headers: {
            'Content-Type': "application/json",
            Pragma: 'no-cache'
        },
        body: JSON.stringify(body)
    })
})