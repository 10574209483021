import * as React from 'react';
import {Link} from "react-router-dom";
import {FormEvent, useEffect, useState} from "react";
import {bookADemo} from "../../api";
import Input from "../Form/Input";
import {EmailRegex, formInputValidate, GenericInputRegex, PhoneNumberRegex} from "../../types";


const PropertyManagementPlatform: React.FunctionComponent = () => {

    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [countryCode, setCountryCode] = useState("1");
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const clearForm = () => {
        setEmail("");
        setCompany("");
        setFirstName("");
        setLastName("");
        setPhone("");
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        setIsError(false);
        bookADemo({
            email: email,
            company: company,
            firstName: firstName,
            lastName: lastName,
            phone: phone.replaceAll("+","").replaceAll("-",""),
            countryCode: countryCode.replaceAll("+","").replaceAll("-","")
        }).then((r) => {
            if (r.ok) {
                clearForm();
            } else {
                setIsError(true);
            }
        }).catch((e) => {
            console.error(e);
            setIsError(true);
        });
    }

    return (<>
        <div className="container px-4 py-5 hero-1">
            <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                <div className="col-lg-6">
                    <img className={"rounded-corners"}
                         src={"/assets/img/landing_1_612.jpg"} style={{width: '100%'}}/>
                </div>
                <div className="col-lg-6">
                    <h1 className="display-5 fw-bold lh-1 mb-3">Powerful property management software, simple
                        pricing</h1>
                    <p className="lead">A powerful platform for management of medium and large scale commercial property
                        that is easy to use for tenants, managers, and investors. Our mission is to automate and more
                        efficiently
                        run your business.
                    </p>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                        <Link to={"/features"}>
                            <button type="button" className="btn btn-primary btn-lg px-4 me-md-2">Features</button>
                        </Link>
                        <Link to={"/pricing"}>
                            <button type="button" className="btn btn-outline-secondary btn-lg px-4">Pricing</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        <div className="container px-4 py-5 hero-1">
            <div className="row  align-items-center g-5 py-5">
                <div className="col-lg-6">
                    <img className={"rounded-corners"}
                         src={"/assets/img/landing_2_612.jpg"} style={{width: '100%'}}/>
                </div>
                <div className="col-lg-6">
                    <h1 className="display-5 fw-bold lh-1 mb-3">Fast payments with same-day collection</h1>
                    <p className="lead">Get paid faster! With same-day collections, we can quickly process tenant
                        payments
                        to reduce returns.</p>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                        <Link to={"/features"}>
                            <button type="button" className="btn btn-primary btn-lg px-4 me-md-2">Features</button>
                        </Link>
                        <Link to={"/pricing"}>
                            <button type="button" className="btn btn-outline-secondary btn-lg px-4">Pricing</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        <div className="container px-4 py-5">
            <div className="row align-items-center g-lg-5 py-5">
                <div className="col-lg-7 text-center text-lg-start">
                    <h1 className="display-4 fw-bold lh-1 mb-3">Book a demo for more information</h1>
                    <p className="col-lg-10 fs-4">Book a demo so we can get in touch. We look forward to streamlining
                        your business.</p>
                </div>
                <div className="mx-auto col-lg-5">
                    <form onSubmit={onSubmit} className="p-4 p-md-5 border rounded-3 bg-light">
                        <div className="mb-3 mt-3">
                            <Input id={"email"}
                                   validation={EmailRegex.expression}
                                   doValidate={formInputValidate}
                                   disabled={false}
                                   value={email}
                                   setValue={setEmail}
                                   required={true}
                                   invalidFeedbackText={EmailRegex.helpText}
                                   type={"email"} label={"Email address"}
                            />
                        </div>
                        <div className="input-group">
                            <label htmlFor={"countryCode"}>Phone</label>
                        </div>
                        <div className="input-group mb-3">

                            <select id="countryCode" className="form-select" style={{maxWidth: 130}}
                                    value={countryCode} onChange={(e) => setCountryCode(e.target.value)}>
                                <option value={"1"}>US (+1)	United States</option>
                                <option value={"93"}>AF (+93)	Afghanistan</option>
                                <option value={"355"}>AL (+355)	Albania</option>
                                <option value={"213"}>DZ (+213)	Algeria</option>
                                <option value={"1"}>AS (+1)	American Samoa</option>
                                <option value={"376"}>AD (+376)	Andorra</option>
                                <option value={"244"}>AO (+244)	Angola</option>
                                <option value={"1"}>AI (+1)	Anguilla</option>
                                <option value={"672"}>AQ (+672)	Antarctica</option>
                                <option value={"1"}>AG (+1)	Antigua and Barbuda</option>
                                <option value={"54"}>AR (+54)	Argentina</option>
                                <option value={"374"}>AM (+374)	Armenia</option>
                                <option value={"297"}>AW (+297)	Aruba</option>
                                <option value={"61"}>AU (+61)	Australia</option>
                                <option value={"43"}>AT (+43)	Austria</option>
                                <option value={"994"}>AZ (+994)	Azerbaijan</option>
                                <option value={"1"}>BS (+1)	Bahamas</option>
                                <option value={"973"}>BH (+973)	Bahrain</option>
                                <option value={"880"}>BD (+880)	Bangladesh</option>
                                <option value={"1"}>BB (+1)	Barbados</option>
                                <option value={"375"}>BY (+375)	Belarus</option>
                                <option value={"32"}>BE (+32)	Belgium</option>
                                <option value={"501"}>BZ (+501)	Belize</option>
                                <option value={"229"}>BJ (+229)	Benin</option>
                                <option value={"1"}>BM (+1)	Bermuda</option>
                                <option value={"975"}>BT (+975)	Bhutan</option>
                                <option value={"591"}>BO (+591)	Bolivia</option>
                                <option value={"387"}>BA (+387)	Bosnia and Herzegovina</option>
                                <option value={"267"}>BW (+267)	Botswana</option>
                                <option value={"55"}>BR (+55)	Brazil</option>
                                <option value={"246"}>IO (+246)	British Indian Ocean Territory</option>
                                <option value={"1"}>VG (+1)	British Virgin Islands</option>
                                <option value={"673"}>BN (+673)	Brunei</option>
                                <option value={"359"}>BG (+359)	Bulgaria</option>
                                <option value={"226"}>BF (+226)	Burkina Faso</option>
                                <option value={"257"}>BI (+257)	Burundi</option>
                                <option value={"855"}>KH (+855)	Cambodia</option>
                                <option value={"237"}>CM (+237)	Cameroon</option>
                                <option value={"1"}>CA (+1)	Canada</option>
                                <option value={"238"}>CV (+238)	Cape Verde</option>
                                <option value={"1"}>KY (+1)	Cayman Islands</option>
                                <option value={"236"}>CF (+236)	Central African Republic</option>
                                <option value={"235"}>TD (+235)	Chad</option>
                                <option value={"56"}>CL (+56)	Chile</option>
                                <option value={"86"}>CN (+86)	China</option>
                                <option value={"61"}>CX (+61)	Christmas Island</option>
                                <option value={"61"}>CC (+61)	Cocos Islands</option>
                                <option value={"57"}>CO (+57)	Colombia</option>
                                <option value={"269"}>KM (+269)	Comoros</option>
                                <option value={"682"}>CK (+682)	Cook Islands</option>
                                <option value={"506"}>CR (+506)	Costa Rica</option>
                                <option value={"385"}>HR (+385)	Croatia</option>
                                <option value={"53"}>CU (+53)	Cuba</option>
                                <option value={"599"}>CW (+599)	Curacao</option>
                                <option value={"357"}>CY (+357)	Cyprus</option>
                                <option value={"420"}>CZ (+420)	Czech Republic</option>
                                <option value={"243"}>CD (+243)	Democratic Republic of the Congo</option>
                                <option value={"45"}>DK (+45)	Denmark</option>
                                <option value={"253"}>DJ (+253)	Djibouti</option>
                                <option value={"1"}>DM (+1)	Dominica</option>
                                <option value={"1"}>DO (+1)	Dominican Republic</option>
                                <option value={"670"}>TL (+670)	East Timor</option>
                                <option value={"593"}>EC (+593)	Ecuador</option>
                                <option value={"20"}>EG (+20)	Egypt</option>
                                <option value={"503"}>SV (+503)	El Salvador</option>
                                <option value={"240"}>GQ (+240)	Equatorial Guinea</option>
                                <option value={"291"}>ER (+291)	Eritrea</option>
                                <option value={"372"}>EE (+372)	Estonia</option>
                                <option value={"251"}>ET (+251)	Ethiopia</option>
                                <option value={"500"}>FK (+500)	Falkland Islands</option>
                                <option value={"298"}>FO (+298)	Faroe Islands</option>
                                <option value={"679"}>FJ (+679)	Fiji</option>
                                <option value={"358"}>FI (+358)	Finland</option>
                                <option value={"33"}>FR (+33)	France</option>
                                <option value={"689"}>PF (+689)	French Polynesia</option>
                                <option value={"241"}>GA (+241)	Gabon</option>
                                <option value={"220"}>GM (+220)	Gambia</option>
                                <option value={"995"}>GE (+995)	Georgia</option>
                                <option value={"49"}>DE (+49)	Germany</option>
                                <option value={"233"}>GH (+233)	Ghana</option>
                                <option value={"350"}>GI (+350)	Gibraltar</option>
                                <option value={"30"}>GR (+30)	Greece</option>
                                <option value={"299"}>GL (+299)	Greenland</option>
                                <option value={"1"}>GD (+1)	Grenada</option>
                                <option value={"1"}>GU (+1)	Guam</option>
                                <option value={"502"}>GT (+502)	Guatemala</option>
                                <option value={"1"}>GG (+1)	Guernsey</option>
                                <option value={"224"}>GN (+224)	Guinea</option>
                                <option value={"245"}>GW (+245)	Guinea-Bissau</option>
                                <option value={"592"}>GY (+592)	Guyana</option>
                                <option value={"509"}>HT (+509)	Haiti</option>
                                <option value={"504"}>HN (+504)	Honduras</option>
                                <option value={"852"}>HK (+852)	Hong Kong</option>
                                <option value={"36"}>HU (+36)	Hungary</option>
                                <option value={"354"}>IS (+354)	Iceland</option>
                                <option value={"91"}>IN (+91)	India</option>
                                <option value={"62"}>ID (+62)	Indonesia</option>
                                <option value={"98"}>IR (+98)	Iran</option>
                                <option value={"964"}>IQ (+964)	Iraq</option>
                                <option value={"353"}>IE (+353)	Ireland</option>
                                <option value={"1"}>IM (+1)	Isle of Man</option>
                                <option value={"972"}>IL (+972)	Israel</option>
                                <option value={"39"}>IT (+39)	Italy</option>
                                <option value={"225"}>CI (+225)	Ivory Coast</option>
                                <option value={"1"}>JM (+1)	Jamaica</option>
                                <option value={"81"}>JP (+81)	Japan</option>
                                <option value={"1"}>JE (+1)	Jersey</option>
                                <option value={"962"}>JO (+962)	Jordan</option>
                                <option value={"7"}>KZ (+7)	Kazakhstan</option>
                                <option value={"254"}>KE (+254)	Kenya</option>
                                <option value={"686"}>KI (+686)	Kiribati</option>
                                <option value={"383"}>XK (+383)	Kosovo</option>
                                <option value={"965"}>KW (+965)	Kuwait</option>
                                <option value={"996"}>KG (+996)	Kyrgyzstan</option>
                                <option value={"856"}>LA (+856)	Laos</option>
                                <option value={"371"}>LV (+371)	Latvia</option>
                                <option value={"961"}>LB (+961)	Lebanon</option>
                                <option value={"266"}>LS (+266)	Lesotho</option>
                                <option value={"231"}>LR (+231)	Liberia</option>
                                <option value={"218"}>LY (+218)	Libya</option>
                                <option value={"423"}>LI (+423)	Liechtenstein</option>
                                <option value={"370"}>LT (+370)	Lithuania</option>
                                <option value={"352"}>LU (+352)	Luxembourg</option>
                                <option value={"853"}>MO (+853)	Macau</option>
                                <option value={"389"}>MK (+389)	Macedonia</option>
                                <option value={"261"}>MG (+261)	Madagascar</option>
                                <option value={"265"}>MW (+265)	Malawi</option>
                                <option value={"60"}>MY (+60)	Malaysia</option>
                                <option value={"960"}>MV (+960)	Maldives</option>
                                <option value={"223"}>ML (+223)	Mali</option>
                                <option value={"356"}>MT (+356)	Malta</option>
                                <option value={"692"}>MH (+692)	Marshall Islands</option>
                                <option value={"222"}>MR (+222)	Mauritania</option>
                                <option value={"230"}>MU (+230)	Mauritius</option>
                                <option value={"262"}>YT (+262)	Mayotte</option>
                                <option value={"52"}>MX (+52)	Mexico</option>
                                <option value={"691"}>FM (+691)	Micronesia</option>
                                <option value={"373"}>MD (+373)	Moldova</option>
                                <option value={"377"}>MC (+377)	Monaco</option>
                                <option value={"976"}>MN (+976)	Mongolia</option>
                                <option value={"382"}>ME (+382)	Montenegro</option>
                                <option value={"1"}>MS (+1)	Montserrat</option>
                                <option value={"212"}>MA (+212)	Morocco</option>
                                <option value={"258"}>MZ (+258)	Mozambique</option>
                                <option value={"95"}>MM (+95)	Myanmar</option>
                                <option value={"264"}>NA (+264)	Namibia</option>
                                <option value={"674"}>NR (+674)	Nauru</option>
                                <option value={"977"}>NP (+977)	Nepal</option>
                                <option value={"31"}>NL (+31)	Netherlands</option>
                                <option value={"599"}>AN (+599)	Netherlands Antilles</option>
                                <option value={"687"}>NC (+687)	New Caledonia</option>
                                <option value={"64"}>NZ (+64)	New Zealand</option>
                                <option value={"505"}>NI (+505)	Nicaragua</option>
                                <option value={"227"}>NE (+227)	Niger</option>
                                <option value={"234"}>NG (+234)	Nigeria</option>
                                <option value={"683"}>NU (+683)	Niue</option>
                                <option value={"850"}>KP (+850)	North Korea</option>
                                <option value={"1"}>MP (+1)	Northern Mariana Islands</option>
                                <option value={"47"}>NO (+47)	Norway</option>
                                <option value={"968"}>OM (+968)	Oman</option>
                                <option value={"92"}>PK (+92)	Pakistan</option>
                                <option value={"680"}>PW (+680)	Palau</option>
                                <option value={"970"}>PS (+970)	Palestine</option>
                                <option value={"507"}>PA (+507)	Panama</option>
                                <option value={"675"}>PG (+675)	Papua New Guinea</option>
                                <option value={"595"}>PY (+595)	Paraguay</option>
                                <option value={"51"}>PE (+51)	Peru</option>
                                <option value={"63"}>PH (+63)	Philippines</option>
                                <option value={"64"}>PN (+64)	Pitcairn</option>
                                <option value={"48"}>PL (+48)	Poland</option>
                                <option value={"351"}>PT (+351)	Portugal</option>
                                <option value={"1"}>PR (+1)	Puerto Rico</option>
                                <option value={"974"}>QA (+974)	Qatar</option>
                                <option value={"242"}>CG (+242)	Republic of the Congo</option>
                                <option value={"262"}>RE (+262)	Reunion</option>
                                <option value={"40"}>RO (+40)	Romania</option>
                                <option value={"7"}>RU (+7)	Russia</option>
                                <option value={"250"}>RW (+250)	Rwanda</option>
                                <option value={"590"}>BL (+590)	Saint Barthelemy</option>
                                <option value={"290"}>SH (+290)	Saint Helena</option>
                                <option value={"1"}>KN (+1)	Saint Kitts and Nevis</option>
                                <option value={"1"}>LC (+1)	Saint Lucia</option>
                                <option value={"590"}>MF (+590)	Saint Martin</option>
                                <option value={"508"}>PM (+508)	Saint Pierre and Miquelon</option>
                                <option value={"1"}>VC (+1)	Saint Vincent and the Grenadines</option>
                                <option value={"685"}>WS (+685)	Samoa</option>
                                <option value={"378"}>SM (+378)	San Marino</option>
                                <option value={"239"}>ST (+239)	Sao Tome and Principe</option>
                                <option value={"966"}>SA (+966)	Saudi Arabia</option>
                                <option value={"221"}>SN (+221)	Senegal</option>
                                <option value={"381"}>RS (+381)	Serbia</option>
                                <option value={"248"}>SC (+248)	Seychelles</option>
                                <option value={"232"}>SL (+232)	Sierra Leone</option>
                                <option value={"65"}>SG (+65)	Singapore</option>
                                <option value={"1"}>SX (+1)	Sint Maarten</option>
                                <option value={"421"}>SK (+421)	Slovakia</option>
                                <option value={"386"}>SI (+386)	Slovenia</option>
                                <option value={"677"}>SB (+677)	Solomon Islands</option>
                                <option value={"252"}>SO (+252)	Somalia</option>
                                <option value={"27"}>ZA (+27)	South Africa</option>
                                <option value={"82"}>KR (+82)	South Korea</option>
                                <option value={"211"}>SS (+211)	South Sudan</option>
                                <option value={"34"}>ES (+34)	Spain</option>
                                <option value={"94"}>LK (+94)	Sri Lanka</option>
                                <option value={"249"}>SD (+249)	Sudan</option>
                                <option value={"597"}>SR (+597)	Suriname</option>
                                <option value={"47"}>SJ (+47)	Svalbard and Jan Mayen</option>
                                <option value={"268"}>SZ (+268)	Swaziland</option>
                                <option value={"46"}>SE (+46)	Sweden</option>
                                <option value={"41"}>CH (+41)	Switzerland</option>
                                <option value={"963"}>SY (+963)	Syria</option>
                                <option value={"886"}>TW (+886)	Taiwan</option>
                                <option value={"992"}>TJ (+992)	Tajikistan</option>
                                <option value={"255"}>TZ (+255)	Tanzania</option>
                                <option value={"66"}>TH (+66)	Thailand</option>
                                <option value={"228"}>TG (+228)	Togo</option>
                                <option value={"690"}>TK (+690)	Tokelau</option>
                                <option value={"676"}>TO (+676)	Tonga</option>
                                <option value={"1"}>TT (+1)	Trinidad and Tobago</option>
                                <option value={"216"}>TN (+216)	Tunisia</option>
                                <option value={"90"}>TR (+90)	Turkey</option>
                                <option value={"993"}>TM (+993)	Turkmenistan</option>
                                <option value={"1"}>TC (+1)	Turks and Caicos Islands</option>
                                <option value={"688"}>TV (+688)	Tuvalu</option>
                                <option value={"1"}>VI (+1)	U.S. Virgin Islands</option>
                                <option value={"256"}>UG (+256)	Uganda</option>
                                <option value={"380"}>UA (+380)	Ukraine</option>
                                <option value={"971"}>AE (+971)	United Arab Emirates</option>
                                <option value={"44"}>GB (+44)	United Kingdom</option>
                                <option value={"598"}>UY (+598)	Uruguay</option>
                                <option value={"998"}>UZ (+998)	Uzbekistan</option>
                                <option value={"678"}>VU (+678)	Vanuatu</option>
                                <option value={"379"}>VA (+379)	Vatican</option>
                                <option value={"58"}>VE (+58)	Venezuela</option>
                                <option value={"84"}>VN (+84)	Vietnam</option>
                                <option value={"681"}>WF (+681)	Wallis and Futuna</option>
                                <option value={"212"}>EH (+212)	Western Sahara</option>
                                <option value={"967"}>YE (+967)	Yemen</option>
                                <option value={"260"}>ZM (+260)	Zambia</option>
                                <option value={"263"}>ZW (+263)	Zimbabwe</option>


                            </select>
                            <Input id={"phone"}
                                   validation={PhoneNumberRegex.expression}
                                   doValidate={formInputValidate}
                                   disabled={false}
                                   value={phone}
                                   setValue={setPhone}
                                   required={true}
                                   invalidFeedbackText={PhoneNumberRegex.helpText}
                                   type={"text"} label={""}
                            />
                        </div>
                        <div className="mb-3">
                            <Input id={"firstName"}
                                   validation={GenericInputRegex.expression}
                                   doValidate={formInputValidate}
                                   disabled={false}
                                   value={firstName}
                                   setValue={setFirstName}
                                   required={true}
                                   invalidFeedbackText={GenericInputRegex.helpText}
                                   type={"text"} label={"First name"}/>
                        </div>
                        <div className="mb-3">
                            <Input id={"firstName"}
                                   validation={GenericInputRegex.expression}
                                   doValidate={formInputValidate}
                                   disabled={false}
                                   value={lastName}
                                   setValue={setLastName}
                                   required={true}
                                   invalidFeedbackText={GenericInputRegex.helpText}
                                   type={"text"} label={"Last name"}/>
                        </div>
                        <div className="mb-3">
                            <Input id={"business"}
                                   validation={GenericInputRegex.expression}
                                   doValidate={formInputValidate}
                                   disabled={false}
                                   value={company}
                                   setValue={setCompany}
                                   required={true}
                                   invalidFeedbackText={GenericInputRegex.helpText}
                                   type={"text"} label={"Business"}/>
                        </div>
                        <button className="w-100 btn btn-lg btn-primary" type="submit">Book a Demo</button>
                        <div className={"col-12 mt-3"}>
                            <div hidden={!isError} className="alert alert-warning" role="alert">
                                The request failed. Please try again.
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>);
};

export default PropertyManagementPlatform;