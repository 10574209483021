import * as React from 'react';
import './index.css';
import {useEffect} from "react";
import {Link} from "react-router-dom";


const Team: React.FunctionComponent = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (<>
        <div id="team-carousel" className="carousel slide" data-bs-ride="carousel">

            <div className="carousel-inner">

                <div className="carousel-item active">
                    <svg className="bd-placeholder-img" width="100%" height="300px" xmlns="http://www.w3.org/2000/svg"
                         aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
                        <rect width="100%" height="100%" fill="#777"></rect>
                    </svg>

                    <div className="container">
                        <div className="carousel-caption">
                            <h1>The PropertyMQ Team</h1>
                            <p>A collection of creators, developers, and more striving to make your business successful</p>
                            <p><Link className="btn btn-lg btn-primary" to={"/contact-us"}>Contact Us</Link></p>
                        </div>
                    </div>
                </div>

            </div>

        </div>
        <div className="container marketing pt-5">

            <div className="row">
                <div className="col-lg-4 text-center">
                    <img src={"/assets/img/team/eckstein_john.png"} style={{width: 140, height:140, borderRadius: 75}} alt={"employee"}/>
                    <h2>John Eckstein</h2>
                    <p>Co-founder</p>
                </div>
                <div className="col-lg-4 text-center">
                    <img src={"/assets/img/team/norris_david.png"} style={{width: 140, height:140, borderRadius: 75}} alt={"employee"}/>

                    <h2>David Norris</h2>
                    <p>Co-founder</p>
                </div>
                <div className="col-lg-4 text-center">
                    <img src={"/assets/img/team/freese_daniella.png"} style={{width: 140, height:140, borderRadius: 75}} alt={"employee"}/>

                    <h2>Daniella Freese</h2>
                    <p>Infrastructure Architect</p>
                </div>
            </div>


            <div className="row">
                <div className="col-lg-4 text-center">
                    <img src={"/assets/img/team/norris_daniel.png"} style={{width: 140, height:140, borderRadius: 75}} alt={"employee"}/>

                    <h2>Daniel Norris</h2>
                    <p>IT Systems Administrator</p>
                </div>
                <div className="col-lg-4 text-center">
                    <img src={"/assets/img/team/mcswain_cody.png"} style={{width: 140, height:140, borderRadius: 75}} alt={"employee"}/>

                    <h2>Cody Mcswain</h2>
                    <p>IT Systems Administrator</p>
                </div>
                <div className="col-lg-4 text-center">
                    <img src={"/assets/img/team/blaser_elliott.png"} style={{width: 140, height:140, borderRadius: 75}} alt={"employee"}/>

                    <h2>Elliott Blaser</h2>
                    <p>Software Developer</p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-4 text-center">
                    <img src={"/assets/img/team/loveless_richard.png"} style={{width: 140, height:140, borderRadius: 75}} alt={"employee"}/>

                    <h2>Richard Loveless III</h2>
                    <p>Software Developer</p>
                </div>
                <div className="col-lg-4 text-center">
                    <img src={"/assets/img/team/powell_rebecca.png"} style={{width: 140, height:140, borderRadius: 75}} alt={"employee"}/>

                    <h2>Rebecca Powell</h2>
                    <p>Software Developer</p>
                </div>
                <div className="col-lg-4 text-center">
                    <img src={"/assets/img/team/stone_brennan.png"} style={{width: 140, height:140, borderRadius: 75}} alt={"employee"}/>

                    <h2>Brennan Stone</h2>
                    <p>Software Developer</p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-4 text-center">
                    <img src={"/assets/img/team/ely_michael.png"} style={{width: 140, height:140, borderRadius: 75}} alt={"employee"}/>

                    <h2>Michael Ely</h2>
                    <p>Software Developer</p>
                </div>
                <div className="col-lg-4 text-center">
                    <img src={"/assets/img/team/leonhard_sean.png"} style={{width: 140, height:140, borderRadius: 75}} alt={"employee"}/>

                    <h2>Sean Leonhard</h2>
                    <p>Software Developer</p>
                </div>

            </div>

        </div>
    </>);
};

export default Team;