import * as React from 'react';
import {Link} from "react-router-dom";



const Footer: React.FunctionComponent = () => {
    return (<>
        <footer className="pt-4 my-md-5 pt-md-5 border-top bg-theme-1" >
            <div className={"container py-3"}>
                <div className="row">
                    <div className="col-lg-3 col-sm-6">
                        <img src={"/assets/img/propertymq_logo.png"} style={{width: 125}}/>
                        <small className="d-block mb-3 text-muted">© 2015–2022</small>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <h5>Who Can We Serve</h5>
                        <ul className="list-unstyled text-small">
                            <li className="mb-1"><Link className="link-secondary text-decoration-none"
                                                    to="/features">Residential</Link></li>
                            <li className="mb-1"><Link className="link-secondary text-decoration-none"
                                                    to="/features">Multi-family</Link></li>
                            <li className="mb-1"><Link className="link-secondary text-decoration-none"
                                                    to="/features">Commercial</Link>
                            </li>

                        </ul>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <h5>Contact Us</h5>
                        <ul className="list-unstyled text-small">
                            <li className="mb-1"><a className="link-secondary text-decoration-none"
                                                    href="#">65 Corban Ave SE</a></li>
                            <li className="mb-1"><a className="link-secondary text-decoration-none" href="#">Concord,
                                NC 28025
                            </a>
                            </li>
                            <li className="mb-1"><a className="link-secondary text-decoration-none" href="mailto:contact@propertymq.com">contact@propertymq.com
                            </a></li>
                            <li className="mb-1"><a className="link-secondary text-decoration-none"
                                                    href="tel:18666701397">866.670.1397</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <h5>Our Company</h5>
                        <ul className="list-unstyled text-small">
                            <li className="mb-1"><Link className="link-secondary text-decoration-none"
                                                    to="/team">Team</Link>
                            </li>
                            <li className="mb-1"><Link className="link-secondary text-decoration-none"
                                                    to="/location">Location</Link></li>
                            <li className="mb-1"><Link className="link-secondary text-decoration-none"
                                                    to="/investors">Investors</Link>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    </>);
};

export default Footer;