import * as React from 'react';
import './index.css';
import MenuItem from "./MenuItem";
import {Link} from "react-router-dom";
import ServicesDropDown from "./ServicesDropDown";


const Navigation: React.FunctionComponent = () => {
    return (<>
        <header className="p-3 bg-theme-1 text-white fixed-top navigation">
            <div className="container">
                <div
                    className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                    <a href="/"
                       className="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none">
                        <img src={"/assets/img/propertymq_logo.png"} style={{width: 200}}/>
                    </a>

                    <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
                        <li className="nav-item dropdown">
                            <ServicesDropDown/>
                        </li>
                        <li><MenuItem text={"Features"} path={"/features"}/></li>
                        <li><MenuItem text={"Pricing"} path={"/pricing"}/></li>
                        <li><MenuItem text={"Investors"} path={"/investors"}/></li>
                    </ul>


                    <div className="text-end">
                        <a href={"https://auth.propertymq.com/a/login"}>
                            <button type="button" className="btn btn-outline-light me-2">Login</button>
                        </a>
                        <a href={"/create-account"}>
                            <button type="button" className="btn btn-signup bg-theme-4 border-theme-4">Sign-up
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </header>
    </>);
};

export default Navigation;