import * as React from 'react';
import {useState} from "react";
import {Link} from "react-router-dom";

type MenuItemProps = {
    path: string,
    text: string
}

const MenuItem: React.FunctionComponent<MenuItemProps> = (p) => {
    const [showSelected, setShowSelected] = useState(false);
    return (<>
        <Link to={p.path} className="nav-link px-2 text-white"
           onMouseEnter={() => {
            setShowSelected(true);
        }}
        onMouseLeave={() => {
            setShowSelected(false);
        }}>{p.text}</Link>
        <div className={(showSelected) ? "navigation-selected" : "navigation-not-selected"}></div>
    </>);
};

export default MenuItem;