import * as React from 'react';
import {useEffect, useState} from 'react';
import {BootstrapValidateClass, ValidateElementFunction} from "../../../types";

type InputProps = {
    id: string,
    validation: RegExp,
    doValidate: ValidateElementFunction,
    value: string,
    setValue: Function,
    required: boolean,
    maxLength?: number,
    minLength?: number,
    invalidFeedbackText: string,
    type: string,
    label: string,
    placeholder?: string,
    disabled?: boolean
}

const Input: React.FunctionComponent<InputProps> = (p) => {

    const [validateClass, setValidateClass] = useState(BootstrapValidateClass.unvalidated);
    const {value} = p;

    useEffect(() => {
        if (value.length === 0 || p.disabled) {
            setValidateClass(BootstrapValidateClass.unvalidated);
            return;
        }
        if (p.doValidate(value, p.required, p.validation)) {
            setValidateClass(BootstrapValidateClass.isvalid);
        } else {
            setValidateClass(BootstrapValidateClass.isinvalid);
        }

    }, [value, p])

    return (<>
        <label htmlFor={p.id}>{p.label}</label>
        <input type={p.type} className={"form-control " + validateClass}
               id={p.id} disabled={p.disabled}
               maxLength={p.maxLength} minLength={p.minLength} placeholder={p.placeholder}
               required={p.required} value={p.value} onChange={e => {
            let value = e.target.value;
            p.setValue(value);
        }}/>
        <div className="invalid-feedback">
            {p.invalidFeedbackText}
        </div>
    </>);
};

export default Input;