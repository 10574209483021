import * as React from 'react';
import './index.css';
import {useEffect} from "react";


const Location: React.FunctionComponent = () => {


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (<>
        <div id="location-carousel" className="carousel slide" data-bs-ride="carousel">

            <div className="carousel-inner">

                <div className="carousel-item active">
                    <div className="container">
                        <div className="carousel-caption">
                            <h1>We are located in Concord North Carolina</h1>
                            <p></p>
                            <p></p>
                        </div>
                    </div>
                </div>

            </div>

        </div>
        <div className="container">
            <div className={"row"}>
                <div className={"col-md-12"} style={{paddingTop: 100, paddingBottom: 400}}>
                    <h2>Office Location and Mailing Address</h2>
                    <h5 className={"mt-3"}>65 Corban Ave SE STE 12</h5>
                    <h5>Concord, NC 28025</h5>
                    <h5 className={"mt-3"}>You can also reach us at</h5>
                    <p>
                        <a className="link-secondary text-decoration-none" href="mailto:ir@propertymq.com">contact@propertymq.com
                        </a>
                    </p>
                    <p>
                        <a className="link-secondary text-decoration-none"
                           href="tel:18666701397">866.670.1397</a>
                    </p>
                </div>
            </div>
        </div>
    </>);
};

export default Location;